import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import {connect, useDispatch} from 'react-redux';
import {
    getContextRoot,
    getApiRoot,
    getLanguageValue,
    getQueryParam,
    navigateToPath,
    googleRecaptcha,
    changeLanguage,
    getFileType,
    genericGetListFromLanguage,
    splitArrayIntoChunks, isInactiveField,
    getExtensionByFileContent
} from "../../common/functions";
import { useParams } from 'react-router-dom';
import lodashGet from 'lodash/get';
import SendIcon from '@mui/icons-material/Send';
import CONSTANTS from '../../common/constants';
import Loader from "../Loader";
import Button from '@material-ui/core/Button';
import { CSS_COLORS } from '../../common/cssColors';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import Modal from '@material-ui/core/Modal';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CropHandler from '../CropHandler';
import lodashSet from "lodash/set";
import PdfHandler from "../PdfHandler";
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import {
  TextField,
  Typography
} from "@mui/material";



function UserUploadsPageBodyComponent(props) {
    const dispatch = useDispatch();
    const contextRoot = getContextRoot();
    const [ isModalOpen, setIsModalOpen ] = useState(false);
    const [ isInvalid, setIsInvalid ] = useState(false);
    const [ isFileValid1, setIsFileValid1 ] = useState(true);
    const [ isFileValid2, setIsFileValid2 ] = useState(true);
    const [ isFileValid3, setIsFileValid3 ] = useState(true);
    const [ isLoader, setIsLoader ] = useState(true);
    const [ isUploadLoader, setisUploadLoader ] = useState(false);
    const [hotelName, setHotelName] = useState("");

    const [ formID, setFormID ] = useState(null);
    // modal vars

    const [needFile1, setNeedFile1] = useState(false);
    const [needFile2, setNeedFile2] = useState(false);

    const [file1, setFile1] = useState(null);
    const [file2, setFile2] = useState(null);

    const [status, setStatus] = useState("initial")
  //  'initial' | 'uploading' | 'success' | 'fail'
//  >('initial');

  const handleFileChange1 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsFileValid1(false)
    console.log(e.target.files)
    if (e.target.files) {

      //alert(file1.size)
      //alert(file1.type)
      if ((e.target.files[0].type.indexOf("image")!=-1 || e.target.files[0].type.indexOf("pdf")!=-1) && (e.target.files[0].size<1161007))
      {
            setIsFileValid1(true)
            setFile1(e.target.files[0]);
      }


    }
  };


  const handleFileChange2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsFileValid2(false)
    console.log(e.target.files)
    if (e.target.files) {
      setFile2(e.target.files[0]);
    if ((e.target.files[0].type.indexOf("image")!=-1 || e.target.files[0].type.indexOf("pdf")!=-1) && (e.target.files[0].size<1161007))
    {
          setIsFileValid2(true)
          setFile2(e.target.files[0]);
    }
    }
  };



  const handleSubmit = () => {
    alert("SUBMITTING FILES...")
  };

  const handleUpload = async () => {
      if (file1 || file2) {
      console.log('Uploading file...');
      setisUploadLoader(true)

      const formData = new FormData();
      if (needFile1)
        formData.append('file1', file1);
      if (needFile2)
        formData.append('file2', file2);

      formData.append('id', formID);
      //formData.append('hotelname', hotelName);
      //ormData.append('hotelphone', hotelPhone);

      try {
        // You can write the URL of your server or any other endpoint used for file upload
        const result = await fetch('https://visalikum.net/api/user-uploads/user-uploads.php', {
          method: 'POST',
          body: formData,
        });

        const data = await result.json();

        //console.log(data)
        //console.log(data.ret)
        if (data.ret=="success")
        {
            //console.log("BABA")
            setisUploadLoader(false)
            setIsInvalid(true)
            setIsFileValid1(false)
            setNeedFile2(false)
            setNeedFile1(false)
            setIsFileValid2(false)

            setIsModalOpen(true)
        }


      } catch (error) {
          //console.log("DUDU")
        console.error(error);
      }
    }
  };



    const routerProps = useParams();

    useEffect(() => {



        console.log(routerProps.id)
        if (!routerProps.id)
            setIsInvalid(true);

        document.title = 'Upload Files Form '+routerProps.id;
        //console.log("https://en.cambodevisa.net/api/user-uploads/user-uploads.php?validate=1&id="+routerProps.id)
        //const query = new URLSearchParams(window.location.search);
        //const id = query.get("id")



          const result =  fetch('https://visalikum.net/api/user-uploads/user-uploads.php?validate=1&id='+routerProps.id, {
            method: 'GET'

          }).then((response) => response.json())
            .then((data) => {
                console.log(data);
                if (data.ret=="0result")
                {

                    setIsInvalid(true);
                    setIsLoader(false)
                }
                else if (data.ret=="valid")
                {
                    setFormID(data.id)
                    if (data.schengen=="1")
                      setNeedFile2(true)
                    if (data.statement=="1")
                      setNeedFile1(true)
                    setIsInvalid(false);
                      setIsLoader(false);
                    }
                else{
                      setIsInvalid(true);
                        setIsLoader(false);
                      }





               })

            .catch((error) => console.log(error));









    }, []);

    if (!routerProps.id) {

        return;
    }

    return <>
        { <AgentManagerStyle>
            <img className={"logo-image"} src={getContextRoot() + 'images/logo.png'} />
            <br/>
            <br/>
            {isLoader ? <>Loading...<CircularProgress size={20} /></> : null }
            <h1>Upload Files - {formID}</h1>
            <div className="input-group">

          {needFile1 && !isInvalid && <div> <h3>Bank Statements From the Last 3 Months</h3>  <input disabled={isLoader} name='statement' id="statement" type="file"  onChange={handleFileChange1} />
          <br/>  <br/>



        { needFile2&& file1 && isFileValid1 && <section>
          <p>✅ </p>
          </section>
        }

        </div>}


        {needFile2 && !isInvalid && <div> <h3>UAE, UK, USA, KSA, Schengen Visa or USA Green Card</h3>  <input disabled={isLoader} name='schengen' id="schengen" type="file"  onChange={handleFileChange2} />


        { needFile2 && file2 && isFileValid2 && <section>
          <p>✅ </p>
          </section>
        }
        <br/><br/> </div>}


        <br/>
        {needFile1 && !needFile2&& <Button disabled={(file1&&!isInvalid&&isFileValid1&&!isUploadLoader)?false:true} variant="contained" endIcon={<SendIcon />}   onClick={handleUpload}>
        Send
      </Button>}

      {needFile2 && !needFile1 &&  <Button disabled={(file2&&!isInvalid&&isFileValid2&&!isUploadLoader)?false:true} variant="contained" endIcon={<SendIcon />}   onClick={handleUpload}>
      Send
    </Button>}

    {needFile2 && needFile1 &&  <Button disabled={(file1&&file2&&!isInvalid&&isFileValid1&&isFileValid2&&!isUploadLoader)?false:true} variant="contained" endIcon={<SendIcon />}   onClick={handleUpload}>
    Send
  </Button>}
      <br/>  <br/>
      {isUploadLoader ? <>Uploading files...<CircularProgress size={20} /></> : null }
      </div>

      {false && file1 && <section>
          File details:
          <ul>
            <li>Name: {file1.name}</li>
            <li>Type: {file1.type}</li>
            <li>Size: {file1.size} bytes</li>
          </ul>
        </section>
      }
      {needFile1 && !isFileValid1 && <section>
            <p><font color="red"> Image/PDF Max 1MB</font></p>

        </section>
      }
      {needFile2 && !isFileValid2 &&<section>
          <p><font color="red"> Image/PDF Max 1MB</font></p>
        </section>
      }




      {false && file2 && <section>
          File details:
          <ul>
            <li>Name: {file2.name}</li>
            <li>Type: {file2.type}</li>
            <li>Size: {file2.size} bytes</li>
          </ul>
        </section>
      }



      <Result status={status}/>


        </AgentManagerStyle>}
        { isInvalid && <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
        There is nothing found
        </Alert>}
        <Modal
            id="modal-component"
            disableBackdropClick={false}
            open={isModalOpen}
            onClose={() => { setIsModalOpen(false); }}
            aria-labelledby="modal-title"
            aria-describedby="modal-content"
        >
            <ModalComponentStyle>
                <div>
                    <div className={"positioning-container"}>
                        <div id="modal-title">Files Uploaded</div>
                        <div id="modal-content">
                        We have received your files and will continue the process. Please wait for your eVisa update by email once ready
                            <div className={"close-button-container"}><Button onClick={() => { setIsModalOpen(false); }}>Close</Button></div>
                        </div>
                    </div>
                </div>
            </ModalComponentStyle>
        </Modal>
    </>;











}

const Result = ({ status }: { status: string }) => {
  if (status === 'success') {
    return <p>✅ File uploaded successfully!</p>;
  } else if (status === 'fail') {
    return <p>❌ File upload failed!</p>;
  } else if (status === 'uploading') {
    return <p>⏳ Uploading selected file...</p>;
  } else {
    return null;
  }
};



const AgentManagerStyle = styled.div`
  text-align: center;
  .general-error-message {
    color: ${CSS_COLORS.FIELD_WARNING};
  }
  .MuiButtonBase-root.MuiButtonBase-root {
    text-transform: none;
  }
  .logo-image {
    max-height: 60px;
  }
  .checked-icon-container {
    display: inline-block;
    position: relative;
    top: 6px;
  }
  .main-table-component {
    position: relative;
    width: 100%;
  }
  .sticky-header {
    top: 0;
    //position: sticky;
    background-color: white;
    z-index: 2;
  }
  .group-short-instructions-inner-container {
    display: inline-block;
    text-align: left;
  }
  .start-procedure-button-container {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .error-message-container {
    font-weight: bold;
    color: ${CSS_COLORS.FIELD_WARNING};
  }
  .persons-buttons-column {
    padding-top: 10px;
    width: 250px;
  }
  .select-person-button {
    border: 1px solid black;
    margin-bottom: 10px;
    &.selected-person {
      background-color: lightgreen;
    }
  }
  .files-information-container {
    text-align: left;
    h2 {
      text-align: center;
    }
  }
  .table-main-h2 {
    background-color: rgba(0,0,255,0.1);
    margin: 0;
  }
  .file-item-container {
    display: inline-block;
    border: 1px dashed black;
    b {
      width: 100%;
      display: inline-block;
      border: 1px dashed black;
    }
  }
  .user-file-upload {
    display: none;
  }
  .client-pictures-component {
    max-width: 100%;
    &.passport {
      max-width: 500px;
    }
    &.passportFace {
      max-width: 350px;
    }
    &.personalIdWithAppendix {
      max-width: 500px;
    }
    &.healthGreenCardOrVaccination {
      max-width: 500px;
    }
    &.birthCertificate {
      max-width: 600px;
    }
    &.parentPassport {
      max-width: 500px;
    }
    &.passportBackside {
      max-width: 500px;
    }
  }
  .agent-action-button {
    border: 1px solid black;
  }
  .user-files-action-button {
    width: 100%;
    border: 1px solid black;
    &.final-upload-confirmation {
      font-weight: bold;
    }
  }
  .upload-error-message {
    max-width: 560px;
  }
  .warning-color {
    font-weight: bold;
    color: ${CSS_COLORS.FIELD_WARNING};
  }
  .stage-header {
    background-color: #f5f5f5;
    border: 1px solid #aaa;
    cursor: pointer;
    height: 60px;
  }
  .stage-content {
    border: 1px solid #aaa;
    border-top: none;
  }
  .stage-content-container {
    padding: 20px;
  }
  .fields-row-container {
    @media (min-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      margin-bottom: 20px;
      &.last {
        margin-bottom: 0;
      }
    }
  }
  .special-text-display {
    color: red;
  }
  .table-component {
    border: 1px solid #aaa;
  }
  .table-header-component {
    background-color: #ddd;
  }
  .align-left {
    text-align: left;
  }
  .citizen-of-any-other-country-question {
    margin-top: 20px;
    padding-bottom: 5px;
    text-align: left;
  }
  .citizen-of-other-country-table {
    td {
      word-break: break-word;
    }
  }
  .stage3-phones-container {
    margin-top: 20px;
    text-align: left;
  }
  .stage5-questions-container {
    text-align: left;
  }
  .disabled-notification {
    color: ${CSS_COLORS.FIELD_WARNING};
  }
  .upload-all-files-button {
    min-width: 150px;
    margin-top: 30px;
    margin-bottom: 10px;
    border: 1px solid black;
  }
  .upload-visa-dropzone-container {
    max-width: 80%;
    margin: 0 auto;
  }
  .upload-visa-dropzone-container.inactive {
    position: relative;
    z-index: -50;
    opacity: 0.4;
  }
  .upload-error-container {
    font-weight: bold;
    color: ${CSS_COLORS.FIELD_WARNING};
  }
  .finish-button, .reject-button {
    font-weight: bold;
    min-width: 150px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid black;
  }
  .finish-button {
    border: 1px solid green;
    color: green;
  }
  .reject-button {
    border: 1px solid ${CSS_COLORS.FIELD_WARNING};
    color: ${CSS_COLORS.FIELD_WARNING};
  }
  .reject-reason-error-text {
    color: ${CSS_COLORS.FIELD_WARNING};
  }
  .reject-reasons-container {
    display: inline-block;
    &.warning {
      border: 1px solid ${CSS_COLORS.FIELD_WARNING};
    }
  }
  .reject-reasons-table, .reject-reasons-table td {
    border: 1px solid;
  }
  .support-container {
    margin-top: 25px;
    margin-bottom: 25px;
  }
`;

const ModalComponentStyle = styled.div`
    outline: none;
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .positioning-container {
      width: 60%;
      position: relative;
      top: 30px;
      @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
        width: auto;
        top: 0;
      }
    }
    #modal-content {
      padding: 10px 10px;
      background-color: white;
    }
    #modal-title {
      height: 50px;
      background-color: lightgray;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .close-button-container {
      text-align: center;
      > button {
        background-color: #fc3;
      }
    }
`;

const UserUploadsPageBody = connect(
    (state) => ({
    }),
    {})(UserUploadsPageBodyComponent);

export default UserUploadsPageBody;
